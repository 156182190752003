import { SimpleModal } from '@emsquared/otto_preact_form';
import { h, Component, render } from 'preact'
class CalendarPopupComponent extends Component<{accountId?:string, teacherId?:string, highlightacc?:string}> {
	render() {
		let src = '/calendar/iframeCal/'
		if(this.props.accountId){
			src += `account/${this.props.accountId}`
		}
		if(this.props.teacherId){
			src += `teacher/${this.props.teacherId}`
		}
		if(this.props.highlightacc){
			src += `/highlightacc/${this.props.highlightacc}`
		}
		return <SimpleModal useStyle={true} label={<i class="glyphicon glyphicon-calendar"></i>}>
			<iframe width="100%" height="100%" src={src}></iframe>
		</SimpleModal>
	}
}
class CalendarPopup extends HTMLElement {
	constructor() {
		super();
		
	}
	connectedCallback() {
		this.render()
	}
	render() {
		let accountId = this.getAttribute('accountId');
		let teacherId = this.getAttribute('teacherId')
		let highlightacc = this.getAttribute('highlightacc')
		render(<CalendarPopupComponent accountId={accountId} teacherId={teacherId} highlightacc={highlightacc} />, this)
	}
}
window.customElements.define('cal-popup', CalendarPopup);